import {css, cx} from '@styled-system/css';
import {icon} from '@styled-system/recipes';
import {Loader2} from 'lucide-react';

const Spinner = () => {
  return (
    <Loader2 className={cx(icon(), css({ animation: 'spin' }))} />
  )
}

export default Spinner;
